/** @jsxRuntime classic */
/** @jsx jsx */
import { gql } from '@apollo/client';
import { Grid } from '@bottlebooks/gatsby-theme-base';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import Exhibitor from '../Exhibitor';

export default function ExploreWinesByExhibitor({ exhibitors, ...rest }) {
  if (!exhibitors) return null;
  return (
    <Grid width={320} height={160} gap={3} {...rest}>
      {exhibitors?.map((exhibitor) => (
        <Exhibitor key={`${exhibitor.brandId}`} exhibitor={exhibitor} />
      ))}
    </Grid>
  );
}
export const fragment = graphql`
  fragment ExploreWinesByExhibitor on Bottlebooks_Brand {
    brandId
    ...Exhibitor
  }
`;

ExploreWinesByExhibitor.fragment = gql`
  fragment ExploreWinesByExhibitor on Brand {
    brandId
    ...Exhibitor
  }
  ${Exhibitor.fragment}
`;
