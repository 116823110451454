/** @jsxRuntime classic */
/** @jsx jsx */
import { gql, useQuery } from '@apollo/client';
import { Container, Link, Text, Title } from '@bottlebooks/gatsby-theme-base';
import EventBanner from '@bottlebooks/gatsby-theme-event/src/components/Event/EventBanner';
import SiteSearchSection from '@bottlebooks/gatsby-theme-event/src/components/LandingPage/SiteSearchSection';
import Layout from '@bottlebooks/gatsby-theme-event/src/components/Layout';
import { LinkProvider } from '@bottlebooks/gatsby-theme-event/src/useLink';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import ExploreWinesByExhibitor from '../../components/ExploreWines/ExploreWinesByExhibitor';
import ExploreWinesSwitcher from '../../components/ExploreWines/ExploreWinesSwitcher';
export default function ExploreByExhibitorPage({ pageContext, data, ...rest }) {
  // Tests if we should display live data. For now, append `?preview` to the URL.
  if (!rest.location.search?.startsWith('?preview')) {
    return <Static pageContext={pageContext} data={data} {...rest} />;
  }
  return (
    <Preview
      collectionId={pageContext.collectionId}
      locale={pageContext.locale}
      paths={pageContext.paths}
      {...rest}
    />
  );
}

/** The statically rendered page, using Gatsby to retrieve data. */
function Static({ pageContext, data }) {
  const { event } = afterQuery(data.bottlebooks);
  return (
    <Success
      event={event}
      locale={pageContext.locale}
      paths={pageContext.paths}
    />
  );
}

/** A preview page, using Apollo client */
function Preview({ collectionId, locale, paths, ...rest }) {
  const { error, loading, data, ...queryRest } = useQuery(QUERY, {
    variables: { collectionId, locale },
  });

  if (error) return <Failure error={error} {...queryRest} {...rest} />;
  if (loading) return <Loading {...queryRest} {...rest} />;
  if (isEmpty(data)) return <Empty {...queryRest} {...rest} />;
  return <Success event={data.event} locale={locale} paths={paths} {...rest} />;
}

function isEmpty(data) {
  return data.event === null;
}

/** The component shown when the data is loaded successfully. @see https://redwoodjs.com/docs/cells */
function Success({ event, locale, paths }) {
  return (
    <LinkProvider value={paths}>
      <Layout locale={locale}>
        <EventBanner event={event} withOverlay={false} />
        <SiteSearchSection locale={locale} />
        <Container>
          <ExploreWinesSwitcher>
            <Link to="/products">
              <Text>
                Or search by vegan, organic, on-trade and 10 other filters….
              </Text>
            </Link>
          </ExploreWinesSwitcher>
          <ExploreWinesByExhibitor
            exhibitors={event.importers}
            sx={{ marginY: 3 }}
          />
        </Container>
      </Layout>
    </LinkProvider>
  );
}

function Failure({ error }) {
  return (
    <Layout>
      <Title>An Error has occurred.</Title>
      <Text>{error.name}</Text>
    </Layout>
  );
}

function Loading(props) {
  return (
    <Layout>
      <Title>Loading…</Title>
      <Text>Please stand by.</Text>
    </Layout>
  );
}

function Empty(props) {
  return (
    <Layout>
      <Title>Nothing found.</Title>
      <Text>We couldn't find any data for this event.</Text>
    </Layout>
  );
}

function afterQuery(data) {
  const event = data.event;

  const importers = event.registeredProducts?.byImporter?.map(
    ({ fieldValue, totalCount, nodes }) => ({
      fieldValue,
      productCount: totalCount,
      ...nodes[0].importer,
    })
  );
  return { event: { ...event, importers }, importers };
}

export const gatsbyQuery = graphql`
  query ExploreByExhibitorPage($locale: Bottlebooks_ContentLocale!) {
    bottlebooks {
      event(eventId: "6033a2d31d00c321389bf5f7", locale: $locale) {
        name
        ...bb_EventBanner
        registeredProducts {
          byImporter: group(
            field: partnerName
            first: 1
            sort: { fields: fieldValue }
          ) {
            fieldValue
            totalCount
            nodes {
              importer: partner {
                ...ExploreWinesByExhibitor
              }
            }
          }
        }
      }
    }
  }
`;

const QUERY = gql`
  query ExploreByExhibitorPage($locale: ContentLocale!, $collectionId: ID!) {
    event(eventId: $collectionId, locale: $locale) {
      eventId
      name
      ...EventBanner
      exhibitors: registrations {
        nodes {
          ...ExploreWinesByExhibitor
        }
      }
    }
  }
  ${EventBanner.fragment}
  ${ExploreWinesByExhibitor.fragment}
`;
