/** @jsxRuntime classic */
/** @jsx jsx */
import { gql } from '@apollo/client';
import { Box, Link, Text } from '@bottlebooks/gatsby-theme-base';
import cssOverlay from '@bottlebooks/gatsby-theme-event/src/helpers/cssOverlay';
import useLink from '@bottlebooks/gatsby-theme-event/src/useLink';
import { graphql } from 'gatsby';
import Image from 'gatsby-image';
import { jsx } from 'theme-ui';

export default function Exhibitor({ exhibitor, ...rest }) {
  const link = useLink();
  return (
    <Link
      fallback="div"
      to={link.exhibitor(exhibitor)}
      sx={{
        border: 2,
        borderColor: 'secondary',
        display: 'flex',
        position: 'relative',
        transition: 'none',
        // backgroundColor: 'primary',
        // color: 'onPrimary',
        ':hover': {
          color: 'onSecondary',
          backgroundColor: 'primary',
          borderColor: 'primary',
        },
        '&:hover .overlay': { backgroundColor: 'overlayLight' },
        // ':nth-of-type(4n+3), :nth-of-type(4n+4)': {
        //   flexDirection: 'row-reverse',
        //   '> .text': { alignItems: 'flex-end' },
        // },
      }}
      {...rest}
    >
      <Text
        variant="gridLabel"
        className="text"
        sx={{
          width: '50%',
          padding: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'center',
        }}
      >
        {exhibitor.name}
      </Text>
      {exhibitor.logo?.fluid && (
        <Box
          sx={{
            width: '50%',
            position: 'relative',
            padding: 3,
            backgroundColor: 'white',
          }}
        >
          <Image fluid={exhibitor.logo.fluid} alt={exhibitor.name} />
          <Box className="overlay" sx={{ ...cssOverlay('transparent') }} />
        </Box>
      )}
    </Link>
  );
}

export const fragment = graphql`
  fragment Exhibitor on Bottlebooks_Brand {
    name
    logo {
      fluid(maxWidth: 320, maxHeight: 320, crop: PAD, background: "#FFF") {
        src
        sizes
        aspectRatio
      }
    }
    ...useLink_exhibitor_bb_Brand
  }
`;

Exhibitor.fragment = gql`
  fragment Exhibitor on Brand {
    name
    logo {
      fixed(width: 320, height: 320, crop: FIT) {
        src
        width
        height
      }
    }
  }
`;
